import React from "react";
import "./aboutUs.css";

const AboutUs = () => {
  const data = [
    {
      itemValue: "1-999 ريال",
      fee: "100 ريال",
      condition:
        "رسم الجدية مستردة إلا في حالتين:\n- رسو المزاد على المشتري\n- مخالفة شروط وأحكام المزاد المشار لها بعنوان الشروط والأحكام.",
    },
    { itemValue: "1000-5000 ريال", fee: "200 ريال", condition: "" },
    { itemValue: "5001-10000 ريال", fee: "350 ريال", condition: "" },
    { itemValue: "10000-50000 ريال", fee: "500 ريال", condition: "" },
    { itemValue: "50000-100000 ريال", fee: "1000 ريال", condition: "" },
    { itemValue: "أكثر من 100000 ريال", fee: "2000 ريال", condition: "" },
  ];

  return (
    <div className="flex justify-center">
      <div className="about-container py-16 max-w-4xl w-full px-4">
        <h1 className="about-us-header text-center text-2xl font-bold mb-4">
          من نحن
        </h1>
        <h3 className="about-us-header text-xl font-bold mb-2">
          منصة بركة تنقسم إلى قسمين رئيسين:
        </h3>
        <p className="about-us-paragraph text-center mb-5">
          القسم الأول: قسم الإعلانات الفردية (سلعة بركة): حيث إنه عند إضافة
          المعلن قيمة إعلانه يتم إضافة مبلغ 1 % في إجمالي قيمة السلعة يتيح
          التطبيق خاصية إبراء الذمة عن طريق المحفظة الإلكترونية اذا تم البيع عن
          طريق التطبيق. (وهذه العمولة يدفعها المعلن، وهي أمانة في ذمته).
        </p>
        <p className="about-us-paragraph text-center mb-5">
          القسم الثاني: قسم المزادات (مزاد بركة): حيث إن نسبة عمولة التطبيق من
          أي عملية مزاد هي 3.5 % من القيمة النهائية لانتهاء المزاد، ولجدية
          المزايد يتوجب على الراغب في الدخول بالمزاد بسداد:
        </p>

        <div className="bg-gray-100 p-5 rounded-lg shadow mb-5">
          <table className="min-w-full bg-white border border-gray-300">
            <caption className="text-center text-lg font-semibold mb-2">
              رسوم الجدية للدخول في المزاد
            </caption>
            <thead>
              <tr className="bg-gray-200 text-gray-700">
                <th className="py-3 px-4 border-b">قيمة السلعة</th>
                <th className="py-3 px-4 border-b">رسم الجدية</th>
                <th className="py-3 px-4 border-b">الحالة</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="text-gray-600">
                  <td className="py-3 px-4 border-b">{row.itemValue}</td>
                  <td className="py-3 px-4 border-b">{row.fee}</td>
                  <td colSpan="2" className="py-3 px-4 border-b">
                    {row.condition.split("\n").map((line, idx) => (
                      <span key={idx}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <p className="about-us-paragraph text-center">
          وعند انتهاء المزاد ستظهر رسالة تأكيد للبائع، إن تمت الموافقة منه على
          السعر الذي وصله المزاد، سيتم إرسال رسالة تأكيد عملية البيع للمشتري،
          يحول المشتري للبائع قيمة السلعة, وعند تأكيد البائع وتحويله نسبة 2.5%
          من السعر الذي انتهى إليه المزاد (وهذه العمولة يدفعها صاحب السلعة، وهي
          أمانة في ذمته) يتم شحن السلعة إلى المشتري، ملاحظه في حالة موافقة كل من
          البائع و المشتري لا يوجد إمكانية لاسترداد قيمة العمولة (السعي) كما هو
          العرف في مجال المزاد.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
