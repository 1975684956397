import React, { useEffect, useRef, useState } from "react";
import camelImg from "../../assets/auctionPoster.jpeg";
import phoneImg from "../../assets/phone with numbers.png";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const OTP = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState(null);

  const [numbers, setNumbers] = useState(Array(6).fill(""));
  const inputRefs = useRef([]);

  const handleInputChange = (e, index) => {
    const newValue = e.target.value;
    const newNumbers = [...numbers];
    newNumbers[index] = newValue;

    setNumbers(newNumbers);

    // Move to the next input if the current input has a value
    if (newValue && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleOTP = async () => {
    try {
      await axios.post(process.env.REACT_APP_BASE_URL + "auth/otp", {
        number: user.number,
        otp: numbers.join("")
      });
      toast.success("تم الدخول بنجاح")
      navigate("/auctions")
    } catch (error) {
      toast.error(error.message);
      toast.error(error.response.data.message);
    }
  };

  async function handleResendOtp() {
    try {
      await axios.post(process.env.REACT_APP_BASE_URL + "auth/resend-otp", {
        user_id: +id,
      });
      toast.success("تم إعادة ارسال الكود");
    } catch (error) {
      toast.error(error.message);
      toast.error(error.response.data.message);
    }
  }

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "users/get-user", {
        params: {
          user_id: +id,
        },
      })
      .then((res) => setUser(res.data.data))
      .catch((err) => console.log(err));
  }, [id]);
  return (
    <div className="py-16 mt-20 mb-15">
      <div className="flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
        {/* Background Image */}
        <div className="hidden lg:block lg:w-1/2 bg-cover">
          <img
            src={camelImg}
            className="h-15"
            alt="Barakkh Logo"
            style={{ height: "100%" }}
          />
        </div>

        {/* Form Section */}
        <div className="w-full p-8 lg:w-1/2">
          <h2
            className="text-2xl font-semibold text-gray-700 text-center"
            style={{ color: "#14181B" }}
          >
            التحقق من الرمز
          </h2>

          {/* Phone Image */}
          <div className="flex justify-center mt-8">
            <img
              src={phoneImg}
              alt="Phone with Numbers"
              className="w-28 h-28"
            />
          </div>

          <p className="mt-3 text-center" style={{ color: "#4E5556" }}>
            أدخل الرمز الذي أرسلناه إلى رقم الهاتف التالي+9665************
          </p>

          <div className="flex items-center justify-center gap-2 mt-4 flex-wrap sm:flex-nowrap">
            {numbers.map((value, index) => (
              <input
                key={index}
                type="text"
                className="w-10 h-10 text-center text-xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-2 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                maxLength="1"
                value={value}
                onChange={(e) => handleInputChange(e, index)}
                ref={(el) => (inputRefs.current[index] = el)}
              />
            ))}
          </div>

          {/* Login Button */}
          <div className="mt-8">
            <button
              className="bg-gray-700 text-white font-bold py-3 px-4 w-full rounded signIn-button"
              style={{ backgroundColor: "#48B3BD" }}
              onClick={() => handleOTP()}
            >
              تحقق من الرمز
            </button>
          </div>

          <div
            className="mt-3 text-center"
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => handleResendOtp()}
          >
            إعادة إرسال الرمز
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTP;
