import React, { useContext, useState } from "react";
import { FaGoogle, FaFacebookF } from "react-icons/fa"; // Import the icons
import "./signIn.css";
import camelImg from "../../assets/auctionPoster.jpeg";
import facebookImg from "../../assets/Facebook.png";
import appleImg from "../../assets/Apple.png";
import googleImg from "../../assets/google.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { UserContext } from "../../context/userContext";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const SignIn = () => {
  const { setUser } = useContext(UserContext);
  const [userInfo, setUserInfo] = useState({
    phone_number: "",
    password: "",
  });
  const navigate = useNavigate();

  async function handleLogin(e) {
    e.preventDefault();
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "auth/login", // Use REACT_APP_BASE_URL
        userInfo
      );
      if (response.status === 200 || response.status === 201) {
        setUser(response.data.data);
        toast.success("تم الدخول بنجاح");
        navigate("/auctions");
      }
    } catch (error) {
      toast.error(error.message);
      toast.error(error.response.data.message);
    }
  }
  return (
    <div className="py-16 mt-20 mb-15">
      <div className="flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
        {/* Background Image */}
        <div className="hidden lg:block lg:w-1/2 bg-cover ">
          <img
            src={camelImg}
            className="h-15"
            alt="Nawader Logo"
            style={{ height: "100%" }}
          />
        </div>

        {/* Form Section */}
        <div className="w-full p-8 lg:w-1/2">
          <h2 className="text-2xl font-semibold text-gray-700 text-center">
            تسجيل دخول
          </h2>

          {/* phone Input */}
          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-bold mb-2 text-right">
              رقم الهاتف
            </label>
            <PhoneInput
              country={"sa"}
              value={userInfo.phone_number}
              onChange={(phone) =>
                setUserInfo({
                  ...userInfo,
                  phone_number: phone,
                })
              }
              inputStyle={{
                width: "100%",
                backgroundColor: "#F3F4F6",
                borderColor: "#D1D5DB",
                borderRadius: "0.375rem",
                padding: "0.5rem 4rem",
              }}
            />
          </div>

          {/* Password Input */}
          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-bold mb-2 text-right">
              كلمة المرور
            </label>
            <input
              className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none text-right"
              type="password"
              value={userInfo.password}
              onChange={(e) =>
                setUserInfo({
                  ...userInfo,
                  password: e.target.value,
                })
              }
            />
          </div>

          <p className="mt-3 text-left">نسيت كلمة المرور؟</p>

          <div className="mt-8">
            <button
              className="bg-gray-700 text-white font-bold py-3 px-4 w-full rounded signIn-button"
              onClick={handleLogin}
            >
              تسجيل دخول
            </button>
          </div>

          {/* Divider */}
          <div className="mt-4 flex items-center justify-between">
            <span className="border-b w-1/5 lg:w-1/4"></span>
            <div className=" text-center text-gray-500 uppercase">أو</div>
            <span className="border-b w-1/5 lg:w-1/4"></span>
          </div>

          <p className="mt-8 text-center text-grey-100">
            لا تملك حساب؟
            {/* <a
              href="/signup"
              className="text-grey-900 hover:text-grey-700 font-semibold"
            >
              
              قم بإنشاء حساب
            </a> */}
          </p>

          <div className="mt-8">
            <button
              className="bg-gray-700 text-white font-bold py-3 px-4 w-full rounded signIn-button"
              onClick={() => {
                navigate("/signup");
              }}
            >
              إنشاء حساب
            </button>
          </div>

          {/* Icon Section with Gray Border */}
          {/* <div className="mt-4 flex justify-center gap-4 p-4">
          
            <div className="flex items-center justify-center h-10 w-10 rounded border border-gray-300">
              <img src={facebookImg} alt="facebook" className=" h-6 w-6" />
            </div>
          
            <div className="flex items-center justify-center h-10 w-10 rounded border border-gray-300 ">
              <img src={appleImg} alt="apple" className=" h-6 w-6" />
            </div>
          
            <div className="flex items-center justify-center h-10 w-10 rounded border border-gray-300 ">
              <img src={googleImg} alt="google" className=" h-6 w-6" />
            </div>
          </div> */}

          {/* Login Button */}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
