import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";

const Users = () => {
  const [users, setUsers] = useState([]);
  const isMobile = useMediaQuery("(max-width:600px)");

  const columns = [
    { field: "id", headerName: "ID", flex: 1, hide: isMobile },
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "number",
      headerName: "رقم الهاتف",
      flex: 1,
    },
  ];

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "users/get-all-users")
      .then((res) => setUsers(res.data.data))
      .catch((err) => toast.error(err.message));
  }, []);

  return (
    <div>
      <Box sx={{ width: "100%", height: "calc(100vh - 100px)" }}>
        <div
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          عدد : {users.length} مستخدم
        </div>
        <DataGrid
          rows={users}
          columns={columns}
          autoHeight={false}
          disableSelectionOnClick
          disableColumnMenu
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[5]}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
            },
            "& .MuiDataGrid-cell": {
              wordWrap: "break-word",
              whiteSpace: "normal",
              fontSize: isMobile ? "12px" : "14px", // Adjust font size for mobile
              padding: isMobile ? "8px" : "16px", // Adjust cell padding for mobile
            },
            "& .MuiDataGrid-root": {
              overflowX: "auto", // Allow horizontal scrolling if needed on smaller screens
            },
          }}
        />
      </Box>
    </div>
  );
};

export default Users;
