import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import axios from "axios";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import useMediaQuery from "@mui/material/useMediaQuery";

const Winnings = () => {
  const [dateFrom, setDateFrom] = useState(dayjs(new Date()));
  const [dateTo, setDateTo] = useState(dayjs(new Date()));
  const isMobile = useMediaQuery("(max-width:600px)");
  const [winnings, setWinnings] = useState([]);

  const columns = [
    { field: "id", headerName: "ID", flex: 1, hide: isMobile },
    {
      field: "name",
      headerName: "اسم الفائز",
      flex: 1,
    },
    {
      field: "number",
      headerName: "رقم الهاتف",
      flex: 1,
    },
    {
      field: "product",
      headerName: "السلعة",
      flex: 1,
    },
    {
      field: "price",
      headerName: "المبلغ المطلوب",
      flex: 1,
    },
  ];
  async function handleShowWinnings(e) {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "auctions/get-all-winnings",
        {
          params: {
            dateFrom: dayjs(dateFrom)
              .hour(0)
              .minute(0)
              .second(0)
              .format("YYYY-MM-DDTHH:mm:ss"),
            dateTo: dayjs(dateTo)
              .hour(23)
              .minute(59)
              .second(59)
              .format("YYYY-MM-DDTHH:mm:ss"),
          },
        }
      );
      console.log(response.data);
      setWinnings(response.data.data);
    } catch (error) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "auctions/get-all-winnings", {
        params: {
          dateFrom: dayjs(dateFrom)
            .hour(0)
            .minute(0)
            .second(0)
            .format("YYYY-MM-DDTHH:mm:ss"),
          dateTo: dayjs(dateTo)
            .hour(23)
            .minute(59)
            .second(59)
            .format("YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((res) => console.log(res.data))
      .catch((err) => toast.error(err.message));
  }, []);
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker", "DatePicker"]}>
          <DatePicker
            label="إلى"
            value={dateTo}
            onChange={(newValue) => setDateTo(newValue)}
          />
          <DatePicker
            label="من"
            value={dateFrom}
            onChange={(newValue) => setDateFrom(newValue)}
          />
          <Button variant="contained" onClick={handleShowWinnings}>
            معاينة
          </Button>
        </DemoContainer>
      </LocalizationProvider>

      <Box sx={{ width: "100%", height: "calc(100vh - 100px)" }}>
        <DataGrid
          rows={winnings}
          columns={columns}
          autoHeight={false}
          disableSelectionOnClick
          disableColumnMenu
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[5]}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
            },
            "& .MuiDataGrid-cell": {
              wordWrap: "break-word",
              whiteSpace: "normal",
              fontSize: isMobile ? "12px" : "14px", // Adjust font size for mobile
              padding: isMobile ? "8px" : "16px", // Adjust cell padding for mobile
            },
            "& .MuiDataGrid-root": {
              overflowX: "auto", // Allow horizontal scrolling if needed on smaller screens
            },
          }}
        />
      </Box>
    </div>
  );
};

export default Winnings;
