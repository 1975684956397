import React, { useState } from "react";
import DashBoardLogin from "../../components/dashboardLogin/dashboardLogin";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BarChartIcon from "@mui/icons-material/BarChart";
import DescriptionIcon from "@mui/icons-material/Description";
import { createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import Users from "../../components/users/users";
import GroupIcon from '@mui/icons-material/Group';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Winnings from "../../components/winnings/winnings";

const NAVIGATION = [
    {
      kind: "header",
      title: "Main items",
    },
    {
      segment: "users",
      title: "Users",
      icon: <GroupIcon />,
    },
    {
      kind: "divider",
    },
    {
      kind: "header",
      title: "Analytics",
    },
    {
      segment: "reports",
      title: "Reports",
      icon: <BarChartIcon />,
      children: [
        {
          segment: "winnings",
          title: "Winnings",
          icon: <EmojiEventsIcon />,
        },
       
      ],
    },
  ];
  
  const demoTheme = createTheme({
    cssVariables: {
      colorSchemeSelector: "data-toolpad-color-scheme",
    },
    colorSchemes: { light: true, dark: true },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 600,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  
  function DemoPageContent({ pathname }) {
    return (
      <Box
        sx={{
          width: "100%",
        }}
      >
        {/* <Typography>Dashboard content for {pathname}</Typography> */}
        {pathname === "/users" ? <Users /> : null}
        {pathname === "/reports/winnings" ? <Winnings /> : null}
      </Box>
    );
  }
  
  DemoPageContent.propTypes = {
    pathname: PropTypes.string.isRequired,
  };

function DashBoard(props) {
  const [isLogin, setIsLogin] = useState(false);
  const [user, setUser] = useState({
    name: "",
    password: "",
  });

  const { window } = props;

  const [pathname, setPathname] = React.useState("/dashboard");

  const router = React.useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => setPathname(String(path)),
    };
  }, [pathname]);

  // Remove this const when copying and pasting into your project.
  const demoWindow = window !== undefined ? window() : undefined;

  const handleLogin = () => {
    if (user.name === "admin" && user.password === "admin") {
      setIsLogin(true);
    } else {
      toast.error("الاسم او كلمة المرور خاطئة");
    }
  };

  return (
    <div>
      {isLogin ? (
        <div>
          <AppProvider
            navigation={NAVIGATION}
            router={router}
            theme={demoTheme}
            window={demoWindow}
            branding={{
              logo: "",
              title: "",
            }}
          >
            <DashboardLayout>
              <DemoPageContent pathname={pathname} />
            </DashboardLayout>
          </AppProvider>
        </div>
      ) : (
        <DashBoardLogin
          user={user}
          setUser={setUser}
          handleLogin={handleLogin}
        />
      )}
    </div>
  );
}
DashBoard.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * Remove this when copying and pasting into your project.
     */
    window: PropTypes.func,
  };
export default DashBoard;
