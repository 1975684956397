import React from "react";
import auctionImg from "../../assets/AuctionImg.jpeg";

const AuctionCard = ({
  id,
  title,
  imgURL,
  startDate,
  endDate,
  quantity,
  price,
  subCategories = [],
  user,
  creator_id,
  handleStartLive,
  hanldeJoinLive,
}) => {
  return (
    <div
      className="mt-4 flex flex-col md:flex-row items-center bg-white border border-gray-200 rounded-lg shadow  hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
      style={{
        width: "100%",
      }}
    >
      <img
        className="object-cover w-full rounded-t-lg  md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
        src={process.env.REACT_APP_BASE_URL + imgURL}
        alt="Technology acquisitions 2021"
      />
      <div
        className="flex flex-col justify-between p-4 leading-normal"
        style={{ width: "100%" }}
      >
        <div className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </div>
        {/* <div>
          {subCategories.map((subCategory) => (
            <span
              class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300"
              style={{
                backgroundColor: "#F2F2F2",
                color: "#939597",
              }}
            >
              {subCategory.name} {subCategory.children[0].name}
            </span>
          ))}

          <span
            class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300"
            style={{
              backgroundColor: "#F2F2F2",
              color: "#939597",
            }}
          >
            550 كيلو
          </span>
          <span
            class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300"
            style={{
              backgroundColor: "#F2F2F2",
              color: "#939597",
            }}
          >
            العدد {quantity}
          </span>
        </div> */}

        {/* <div className="mt-4">
          <span
            class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300"
            style={{
              backgroundColor: "red",
              color: "white",
            }}
          >
            {price} ريال
          </span>

          <span
            class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300"
            style={{
              backgroundColor: "#F2F2F2",
              color: "#939597",
            }}
          >
            12 دقيقة
          </span>
        </div> */}

        <div className="mt-4">
          {user.id === creator_id ? (
            <button
              className="bg-gray-700 text-white font-bold py-3 px-4 w-full rounded signIn-button"
              onClick={() => handleStartLive(id, startDate, endDate)}
              style={{
                backgroundColor: "green",
                width: "100%",
              }}
            >
              ابدأ اللايف
            </button>
          ) : (
            <button
              className="bg-gray-700 text-white font-bold py-3 px-4 w-full rounded signIn-button"
              onClick={() => handleStartLive(id, startDate, endDate)}
              style={{
                backgroundColor: "green",
                width: "100%",
              }}
            >
              مشاهدة اللايف
            </button>
            // <span
            //   class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300"
            //   style={{
            //     backgroundColor: "green",
            //     color: "white",
            //     cursor: "pointer",
            //   }}
            //   onClick={() => handleStartLive(id)}
            // >
            //   ابدأ اللايف
            // </span>
            // <span
            //   class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300"
            //   style={{
            //     backgroundColor: "green",
            //     color: "white",
            //     cursor: "pointer",
            //   }}
            //   onClick={() => hanldeJoinLive(id)}
            // >
            //   مشاهدة اللايف
            // </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuctionCard;
