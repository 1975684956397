import React, { useContext, useState } from "react";
import camelImg from "../../assets/auctionPoster.jpeg";
import "react-phone-input-2/lib/style.css";
import { UserContext } from "../../context/userContext";
import "./signup.css";
import { toast } from "react-toastify";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const { setUser } = useContext(UserContext);
  const [userInfo, setUserInfo] = useState({
    name: "",
    phone_number: "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  async function handleSignUp(e) {
    e.preventDefault();
    if (!userInfo.phone_number.startsWith("966")) {
      toast.error("برجاء ادخال رقم الجوال بشكل صحيح");
      return;
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "auth/signup",
        userInfo
      );
      if (response.status === 200 || response.status === 201) {
        setUser(response.data.data);
        toast.success("تم انشاء الحساب بنجاح");
        navigate(`/otp/${response.data.data.userId}`);
      }
    } catch (error) {
      toast.error(error.message);
      toast.error(error.response.data.message);
    }
  }

  return (
    <div className="py-16 mt-20 mb-15">
      <div className="flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
        <div className="hidden lg:block lg:w-1/2 bg-cover">
          <img
            src={camelImg}
            className="h-15"
            alt="Nawader Logo"
            style={{ height: "100%" }}
          />
        </div>

        <div className="w-full p-8 lg:w-1/2">
          <h2 className="text-2xl font-semibold text-gray-700 text-center">
            إنشاء حساب
          </h2>

          {/* Name Input */}
          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-bold mb-2 text-right">
              الاسم
            </label>
            <input
              className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none text-right"
              type="text"
              value={userInfo.name}
              onChange={(e) =>
                setUserInfo({
                  ...userInfo,
                  name: e.target.value,
                })
              }
            />
          </div>

          {/* Phone Input with Saudi Arabia Flag */}
          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-bold mb-2 text-right">
              رقم الهاتف
            </label>
            <PhoneInput
              country={"sa"}
              value={userInfo.phone_number}
              onChange={(phone) =>
                setUserInfo({
                  ...userInfo,
                  phone_number: phone,
                })
              }
              inputStyle={{
                width: "100%",
                backgroundColor: "#F3F4F6",
                borderColor: "#D1D5DB",
                borderRadius: "0.375rem",
                padding: "0.5rem 4rem",
              }}
            />
          </div>

          {/* Password Input */}
          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-bold mb-2 text-right">
              كلمة المرور
            </label>
            <input
              className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none text-right"
              type="password"
              value={userInfo.password}
              onChange={(e) =>
                setUserInfo({
                  ...userInfo,
                  password: e.target.value,
                })
              }
            />
          </div>

          {/* Confirm Password Input */}
          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-bold mb-2 text-right">
              تأكيد كلمة المرور
            </label>
            <input
              className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none text-right"
              type="password"
              value={userInfo.confirmPassword}
              onChange={(e) =>
                setUserInfo({
                  ...userInfo,
                  confirmPassword: e.target.value,
                })
              }
            />
          </div>

          {/* Sign Up Button */}
          <div className="mt-8">
            <button
              className="bg-gray-700 text-white font-bold py-3 px-4 w-full rounded signUp-button"
              onClick={handleSignUp}
            >
              إنشاء الحساب
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
