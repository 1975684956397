import React from "react";
import camelImg from "../../assets/camel.png";

const DashBoardLogin = ({user, setUser, handleLogin}) => {
  return (
    <div className="py-16 mt-20 mb-15">
      <div className="flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
        {/* Background Image */}
        <div className="hidden lg:block lg:w-1/2 bg-cover ">
          <img src={camelImg} className="h-15" alt="Nawader Logo" />
        </div>

        {/* Form Section */}
        <div className="w-full p-8 lg:w-1/2">
          <h2 className="text-2xl font-semibold text-gray-700 text-center">
            تسجيل دخول للوحة التحكم
          </h2>

          {/* phone Input */}
          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-bold mb-2 text-right">
              الاسم
            </label>
            <input
              className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none text-right"
              type="text"
              value={user.name}
              onChange={(e) =>
                setUser({
                  ...user,
                  name: e.target.value,
                })
              }
            />
          </div>

          {/* Password Input */}
          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-bold mb-2 text-right">
              كلمة المرور
            </label>
            <input
              className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none text-right"
              type="password"
              value={user.password}
              onChange={(e) =>
                setUser({
                  ...user,
                  password: e.target.value,
                })
              }
            />
          </div>



          <div className="mt-8">
            <button
              className="bg-gray-700 text-white font-bold py-3 px-4 w-full rounded signIn-button"
              onClick={handleLogin}
            >
              تسجيل دخول
            </button>
          </div>


        </div>
      </div>
    </div>
  );
};

export default DashBoardLogin;
